html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.fixed-bar {
  position: absolute;
  top: 60px; /* Ajuste conforme a altura do header */
  left: 0;
  width: 100%;
  background: #333; /* Cor de fundo para a barra */
  color: white;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 90px;
}

.participe-programacoes h1{
  margin-top: 30px;
  font-size: 22px;
}

.LogosApoiadores{
  margin-top: 200px;
}

header .navigation{
  position: relative;
  line-height: 75px;
}

.nilceia-textos h1{
  font-size: 24px;
}

.nilceia-textos p{
  font-size: 20px;
  margin-top: 30px;
}

.nilceia-textos a{
  font-size: 20px;
}

header .navigation .menu{
  position: relative;
  display: flex;
  justify-content: center;
  list-style: none;
  user-select: none;
}

.menu-item > a{
  color: #fff;
  font-size: 1em;
  text-decoration: none;
  margin: 20px;
  padding: 25px 0;
}

.menu-item > a:hover{
  color: #469dff;
  transition: 0.3s;
}

.flianLogo{
  width: 150px;
  height: 150px;
}


.obrasContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 110%; /* Limita a largura máxima da box */
  margin: 0 auto; /* Centraliza a box horizontalmente */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Ajusta a sombra */
  background: white;
}

.livros-homenageado {
  display: flex;
  flex-direction: row; /* Exibe as obras em linha */
  flex-wrap: wrap; /* Permite quebra de linha se necessário */
  justify-content: center; /* Centraliza as obras horizontalmente */
  gap: 15px; /* Espaçamento entre as obras */
}

.obra {
  display: flex;
  flex-direction: column; /* Coloca o texto acima da imagem */
  align-items: center;
  text-align: center;
}

.obras {
  max-width: 150px; /* Reduz o tamanho das imagens */
  height: auto;
}

.obra p {
  margin-left: 20px;
  font-size: 0.8em; /* Ajusta o tamanho da fonte */
  color: #333;
}

.livros-homenageado > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.obras {
  padding-left: 20px;
}

.textos p {
  text-align: center;
  font-family: 'garet-book';
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 30px; /* Espaçamento entre os parágrafos */
}

.textos ul li{
  font-size: 20px;
}

.textos ul{
  margin-bottom: 30px;
}


.textos {
  text-align: center;
}

.float-container {
  padding: 20px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.imagem-participe {
  height: 'auto';
  width: 700px;
  margin-top: 170px;
}

.float-child1 {
  width: 50%;
  float: left;
  padding: 20px;
  font-size: 30px;
  margin: 0 auto 20px auto;
  background: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.float-child img {
  width: 50%;
  margin-right: 9%;
  float: left;
  padding: 20px;
}


.texto-central{
  margin-top: 2%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.imagens{
  margin-top: 3%;
  width: 100%;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 5%;
  gap: 40px;
}

.imagem {
  border: black solid 1px;
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    margin-top: 70px;
}

.logoContainer{
  background-color: rgb(70, 226, 213);
  width: 100%;
  height: 50vh;
}

.header{
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  border: black solid 1px;
  width: 100%;
  height: 150px;
  position: fixed;
}

.footer{
  background-color: black;
  color: white;
  width: 100%;
  height: 50px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white; 
  text-decoration:none; 
  cursor:pointer;  
}

.icon-bar {
  position: fixed;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a.facebook:hover {
  background-color: #08AEB4;
}

.icon-bar a.instagram:hover {
  background-color: #08AEB4;
}

.icon-bar a.whatsapp:hover {
  background-color: #08AEB4;
}

.facebook {
  background: black;
  color: white;
}

.instagram {
  background: black;
  color: white;
}

.whatsapp {
  background: black;
  color: white;
}

.programacoes-container {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  margin-top: 80px;
}

.programacoes {
  display: inline-block;
  width: 80%;
  padding: 20px;
  font-size: 20px;
  margin: 0 auto 20px auto;
  background: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.glow-on-hover {
  width: 220px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}


 
.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #272777, #1414ff, #2176b7, #2391d5, #00ffd5, #002bff, #44a9d1, #84b8fc, #78bdfe);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}


.botaoContainer {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding-bottom: 15px;
}


.vertical-divider {
  width: 1px; /* Largura da linha vertical */
  background-color: #000; /* Cor da linha vertical */
  height: auto; /* Ajusta a altura automaticamente */
  align-self: stretch; /* Faz com que a linha vertical se estenda ao longo da altura das seções */
}


li {
  margin-top: 5px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

.content h1 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}
.content h2 {
  color: #666;
  margin-top: 20px;
  margin-bottom: 10px;
}
.content p, .content li {
  font-size: 16px;
  margin-bottom: 10px;
}
.content ul {
  list-style-type: none;
  padding: 0;
}
.content li::before {
  content: "• ";
  color: #007BFF;
}

.content a{
  content: "• ";
  color: blue;
}


.participe-programacoes p{
  margin-top: 30px;
}

.programacoes1 {
  margin-top: 300px;
  width: 50%;
  float:inline-end;
  padding: 20px;
  font-size: 20px;
}

.image-container {
  display: flex;
  justify-content: center; /* Centraliza a imagem horizontalmente */
  align-items: center; /* Opcional: Centraliza a imagem verticalmente, se necessário */
  margin-top: 170px; /* Margem superior se precisar de espaço acima da imagem */
}



.dosis-titlefont {
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.cardo-regular {
  font-family: "Cardo", serif;
  font-weight: 400;
  font-style: normal;
}

.cardo-bold {
  font-family: "Cardo", serif;
  font-weight: 700;
  font-style: normal;
}

.cardo-regular-italic {
  font-family: "Cardo", serif;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'glacial-indifference';
  src: url('D:/Site Casa Amarela/projetocasa/src/fonts/glacial-indifference.regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.glacial {
  font-family: 'glacial-indifference';
}

@font-face {
  font-family: 'garet-book';
  src: url('D:/Site Casa Amarela/projetocasa/src/fonts/Garet-Book.otf');
  font-weight: normal;
  font-style: normal;
}

.garet-book {
  font-family: 'garet-book';
}

@media (max-width: 1200px) {
  header {
    padding: 0 50px;
  }
}

@media (max-width: 992px) {
  header {
    padding: 0 30px;
  }
  .float-child, .float-child1 {
    width: 100%;
    float: none;
    margin-top: 100px;
  }
  .LogosApoiadores{
    margin-top: 400px;
  }
  .imagem-flian{
    width: 100%;
    height: auto;
  }

  .imagens {
    flex-direction: column;
    align-items: center;
  }
  .imagem {
    margin-top: 30px;
  }
  
  .programacoes1 {
    width: 80%;
    margin: 0 auto;
  }
  .programacoes{
    margin-top: 100px;
  }
}

.imagem-flian{
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  header {
    padding: 0 20px;
  }
  .menu-item > a {
    padding: 15px 0;
    margin: 10px;
  }
  .float-child img {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 576px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }
  .flianLogo {
    width: 100px;
    height: 100px;
  }
  .float-child, .float-child1 {
    width: 100%;
    padding: 10px;
  }
  .imagens {
    flex-direction: column;
    align-items: center;
  }
  .imagem {
    margin-top: 20px;
  }
  .programacoes1 {
    width: 100%;
    margin: 0 auto;
  }
  .botaoContainer {
    flex-direction: column;
  }
}

header {
  z-index: 999;
  position: fixed; /* Garante que o header fique fixo no topo */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Permite que os itens se movam para a linha seguinte quando necessário */
  justify-content: space-between;
  align-items: center;
  background: black;
  box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
  color: white;
  padding: 0 20px; /* Padding padrão */
  transition: 0.6s;
}

header .logo-container {
  flex: 1; /* Permite que o logo ocupe o espaço disponível */
}

header .navigation {
  display: flex;
  flex: 2; /* Permite que o menu ocupe mais espaço */
  justify-content: center; /* Centraliza os itens do menu */
  flex-wrap: wrap; /* Permite que o menu quebre em múltiplas linhas se necessário */
}

header .navigation .menu {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap; /* Permite que os itens do menu quebrem linha */
}

.menu-item > a {
  color: #fff;
  font-size: 1em;
  text-decoration: none;
  margin: 10px;
  padding: 10px 0; /* Ajusta o padding para menor altura */
}

.menu-item > a:hover {
  color: #469dff;
  transition: 0.3s;
}

@media (max-width: 992px) {
  header {
    padding: 0 15px; /* Ajusta padding para telas menores */
  }
  header .logo-container,
  header .navigation {
    flex: 1 100%; /* Faz com que os itens se empilhem verticalmente */
  }
}

@media (max-width: 768px) {
  header {
    flex-direction: column; /* Alinha itens verticalmente */
    align-items: flex-start; /* Alinha itens à esquerda */
    padding: 0 10px; /* Reduz o padding */
  }
  .menu-item > a {
    font-size: 0.8em; /* Ajusta o tamanho da fonte */
    margin: 5px 0; /* Ajusta o espaçamento entre itens */
    padding: 8px 0; /* Ajusta o padding vertical */
  }
}

@media (max-width: 576px) {
  header {
    padding: 0 5px; /* Ajusta o padding para dispositivos móveis */
  }
  .menu-item > a {
    font-size: 0.7em; /* Ajusta ainda mais o tamanho da fonte */
    margin: 3px 0; /* Ajusta o espaçamento entre itens */
    padding: 5px 0; /* Ajusta o padding vertical */
  }
}


