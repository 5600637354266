header {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: black;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    color: black;
    padding: 0 100px;
    transition: 0.6s;
  }
  
  header .navigation{
    position: relative;
    line-height: 75px;
  }
  
  header .navigation .menu{
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
    user-select: none;
  }
  
  .menu-item > a{
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    margin: 20px;
    padding: 25px 0;
  }
  
  .menu-item > a:hover{
    color: #469dff;
    transition: 0.3s;
  }

  

  /* Estilos para imagens responsivas */
.responsive-img {
  width: 100%; /* Garante que a imagem ocupe toda a largura do contêiner */
  height: auto; /* Mantém a proporção da imagem */
  max-width: 100%; /* Garante que a imagem não exceda a largura do contêiner */
  margin-top: 20px; /* Espaçamento superior para imagens */
}

@media (max-width: 1200px) {
  .responsive-img {
    width: 90%; /* Ajusta a largura para telas médias */
    margin-top: 15px; /* Ajusta o espaçamento superior */
  }
}

@media (max-width: 992px) {
  .responsive-img {
    width: 80%; /* Ajusta a largura para tablets */
    margin-top: 10px; /* Ajusta o espaçamento superior */
  }
}

@media (max-width: 768px) {
  .programacoes-container{
    margin-top: 400px;
  }
  .responsive-img {
    width: 70%;
    height: 70%; /* Ajusta a largura para telas menores */
    margin-top: 10px; /* Ajusta o espaçamento superior */
  }
}

@media (max-width: 576px) {
  .responsive-img {
    width: 100%; /* Ajusta a largura para dispositivos móveis */
    margin-top: 5px; /* Ajusta o espaçamento superior */
  }
}
