/* Ajuste o contêiner flutuante para suportar a reordenação */
.floatContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  font-size: 10px;
  color: rgb(0, 0, 0);
  margin-top: 160px;
}

/* Estilos para o bloco flutuante com texto */
.floatChild {
  padding: 20px;
  font-size: 10px;
  background: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%;
  box-sizing: border-box;
  order: 2; /* Ordem padrão para desktop */
}

/* Estilos para o bloco flutuante com imagem */
.floatChild1 {
  padding: 20px;
  font-size: 10px;
  flex: 1 1 45%;
  order: 1; /* Ordem padrão para desktop */
}

/* Ajustes para telas menores */
@media (max-width: 768px) {
  .floatContainer {
    margin-top: 400px;
  }

  .floatChild,
  .floatChild1 {
    flex: 1 1 100%; /* Largura total para telas menores */
    margin: 0 auto 20px auto;
    margin-top: 40px;
  }

  .floatChild {
    order: 2; /* Imagem acima no mobile */
  }

  .floatChild1 {
    order: 1; /* Texto abaixo no mobile */
  }
}
